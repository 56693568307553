import React, { useState, useCallback, useMemo } from 'react';
import { Button, ButtonProps, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { HeartFilledIcon, HeartIcon } from 'src/theme/icons';
import useCustomSWR from 'src/lib/hooks/use-custom-swr';
import { AllFavoritesBoardResponse, FavoritesBoard, WorkoutResponse } from 'src/lib/types';
import useAPI from 'src/lib/hooks/use-api';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	isLoading?: false;
	workoutId: WorkoutResponse['id'];
};

type CommonProps = {
	variant: 'icon' | 'text';
	buttonProps?: ButtonProps;
	// Ideally this component would handle this internally since we handle other mutations.
	// But handling it internally would require creating a modal for every single
	// <AddToFavoritesButton/>, which would be quite expensive on pages like /workouts.
	onCreateNewGroup: () => void;
};

export type AddToFavoritesButtonProps = (LoadingProps | ResolvedProps) & CommonProps;

const AddToFavoritesButton = (props: AddToFavoritesButtonProps) => {
	const api = useAPI();
	const [isMutating, setIsMutating] = useState(false);
	const { data: favoriteGroups, isLoading, revalidate } = useCustomSWR<AllFavoritesBoardResponse>('users/favorites');

	const isParentOrSelfLoading = (props.isLoading ?? false) || isLoading;

	const isSavedInAtLeastOneGroup = useMemo(() => {
		if (props.isLoading || !favoriteGroups) {
			return false;
		}

		return favoriteGroups.some(group => group.workouts.some(w => w.id === props.workoutId));
	}, [favoriteGroups, props]);

	const handleToggleOnGroup = useCallback(async (id: FavoritesBoard['id']) => {
		const group = favoriteGroups?.find(group => group.id === id);

		if (!group || props.isLoading) {
			return;
		}

		setIsMutating(true);

		const newIds = group.workouts.some(w => w.id === props.workoutId) ? group.workouts.filter(w => w.id !== props.workoutId).map(w => w.id) : [...group.workouts.map(w => w.id), props.workoutId];

		try {
			await api.updateFavoritesBoard(id, {
				...group,
				ids: newIds
			});
			await revalidate();
		} catch { }

		setIsMutating(false);
	}, [api, favoriteGroups, props, revalidate]);

	const iconToUseForButton = useMemo(() => {
		if (props.variant === 'icon') {
			if (isSavedInAtLeastOneGroup) {
				return <HeartFilledIcon boxSize={6} />;
			}

			return <HeartIcon boxSize={6} />;
		}

		return undefined;
	}, [isSavedInAtLeastOneGroup, props.variant]);

	const leftIconToUseForButton = useMemo(() => {
		if (props.variant === 'text') {
			if (isSavedInAtLeastOneGroup) {
				return <HeartFilledIcon boxSize={6} />;
			}

			return <HeartIcon boxSize={6} color="red.600" />;
		}

		return undefined;
	}, [isSavedInAtLeastOneGroup, props.variant]);

	return (
		<Menu closeOnSelect={false}>
			{({ isOpen }) => (
				<>

					<MenuButton
						as={props.variant === 'text' ? Button : IconButton}
						aria-label="Add to favorites board"
						variant="link"
						icon={iconToUseForButton}
						color="black"
						leftIcon={leftIconToUseForButton}
						rightIcon={props.variant === 'text' ? <ChevronDownIcon /> : undefined}
						rounded={props.variant === 'icon' ? 'md' : undefined}
						isDisabled={isParentOrSelfLoading || isMutating}
						isLoading={props.variant === 'icon' && isMutating}
						size={props.variant === 'icon' ? 'xs' : undefined}
						{...props.buttonProps}
					>
						{props.variant === 'text' ? 'Favorite' : ''}
					</MenuButton>
					<Portal>
						<MenuList
							py={0}
							flexDirection="column"
							display={isOpen ? 'flex' : 'none'}
						>
							{
								!props.isLoading && favoriteGroups?.map(group => (
									<MenuItem
										key={group.id}
										isDisabled={isMutating}
										_focus={{ textDecoration: 'underline' }}
										fontWeight="semibold"
										maxW="20ch"
										overflow="hidden"
										textOverflow="ellipsis"
										whiteSpace="nowrap"
										display="inline-block"
										onClick={async () => handleToggleOnGroup(group.id)}
									>
										{group.workouts.some(w => w.id === props.workoutId) && (
											<HeartFilledIcon mr={2} />
										)}

										{group.title}
									</MenuItem>
								))
							}

							<MenuItem
								bgColor="red.600"
								color="white"
								fontWeight="semibold"
								transitionProperty="common"
								transitionDuration="normal"
								_focus={{ bgColor: 'black' }}
								isDisabled={isMutating}
								onClick={props.onCreateNewGroup}
							>
								<AddIcon mr={2} />
								Create new board
							</MenuItem>
						</MenuList>
					</Portal>
				</>
			)}
		</Menu>
	);
};

export default React.memo(AddToFavoritesButton);

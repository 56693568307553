import {useCallback, useMemo} from 'react';
import {CalendarOptions, GoogleCalendar, ICalendar, OutlookCalendar, YahooCalendar} from 'datebook';
import CONFIG from '../config';

export enum CalendarProvider {
	APPLE = 'Apple / iCal',
	GOOGLE = 'Google',
	OUTLOOK = 'Outlook',
	YAHOO = 'Yahoo'
}

export type useCalendarEventProps = CalendarOptions & {uri: string};

const useCalendarEvent = ({uri, ...props}: useCalendarEventProps) => {
	const url = useMemo(() => `${CONFIG.BASE_URL}${uri}`, [uri]);

	const promptAddFor = useCallback((provider: CalendarProvider) => {
		const descriptionWithUrl = `${props.description ?? ''}\n\n${url}`;

		switch (provider) {
			case CalendarProvider.APPLE: {
				const ics = new ICalendar(props);
				ics.addProperty('URL', url);
				ics.download();
				return;
			}

			case CalendarProvider.GOOGLE: {
				const event = new GoogleCalendar({...props, description: descriptionWithUrl});
				window.open(event.render(), '_blank');
				return;
			}

			case CalendarProvider.OUTLOOK: {
				const event = new OutlookCalendar({...props, description: descriptionWithUrl});
				window.open(event.render(), '_blank');
				return;
			}

			case CalendarProvider.YAHOO: {
				const event = new YahooCalendar({...props, description: descriptionWithUrl});
				window.open(event.render(), '_blank');
				return;
			}

			default:
				throw new Error('Unknown calendar provider.');
		}
	}, [props, url]);

	return {promptAddFor};
};

export default useCalendarEvent;

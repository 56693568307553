import {ThemeTypings} from '@chakra-ui/styled-system';

const getAccentColorFromScheme = (scheme: ThemeTypings['colorSchemes']) => {
	switch (scheme) {
		case 'red':
			return 'red.300';
		case 'purple':
			return 'purple.200';
		case 'teal':
			return 'teal.600';
		case 'blue':
			return 'blue.400';
		case 'yellow':
			return 'yellow.400';
		default:
			return 'black';
	}
};

export default getAccentColorFromScheme;

import {useCalendarEventProps} from './hooks/use-calendar-event';
import parseDateString from './parse-date-str';
import {WorkoutResponse} from './types';

const getCalendarEventForWorkout = (workout?: WorkoutResponse): useCalendarEventProps => {
	if (workout) {
		const start = parseDateString(workout.date);
		start.setHours(8, 0);

		const end = new Date(start);
		const durationInHours = Math.floor(workout.maxLength / 60);
		const durationInMinutes = workout.maxLength % 60;
		end.setHours(end.getHours() + durationInHours, end.getMinutes() + durationInMinutes);

		return {
			title: workout.type,
			description: workout.description,
			start,
			end,
			uri: `/workouts/${workout.id}`
		};
	}

	return {
		title: 'Default Event',
		description: 'Description for this default event',
		uri: '/workouts',
		location: 'Online',
		start: new Date(),
		end: new Date(Date.now() + (1000 * 60 * 60))
	};
};

export default getCalendarEventForWorkout;

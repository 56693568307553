const simpleStringHash = (string: string, divisor: number) => {
	let hash = 0;

	for (let i = 0; i < string.length; i++) {
		hash += string.charCodeAt(i);
	}

	return hash % divisor;
};

export default simpleStringHash;

import {useEffect, useMemo} from 'react';
import {API} from '../api';
import useAuth from './use-auth';

const useAPI = () => {
	const {token, handleLogout} = useAuth();

	const api = useMemo(() => new API({
		onError: error => {
			if (error.response.status === 403) {
				handleLogout();
			}
		}
	}), [handleLogout]);

	useEffect(() => {
		if (token) {
			api.setToken(token);
		}
	}, [token, api]);

	return api;
};

export default useAPI;

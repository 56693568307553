import {Heading, VStack, HStack, Spacer, ModalContentProps, ModalProps,
	Alert,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	AlertIcon,
	AlertDescription,
	ModalFooter,
	Button
} from '@chakra-ui/react';

import React from 'react';

type StatefulModalProps = {
	heading: string;
	size?: ModalProps['size'];
	primaryAction?: string;
	secondaryAction?: string;
	tertiaryAction?: string;
	isPrimaryActionDisabled?: boolean;
	onPrimaryAction?: () => void;
	onSecondaryAction?: () => void;
	onTertiaryAction?: () => void;
	modalContentProps?: ModalContentProps;
	isOpen: boolean;
	onClose: () => void;
	isLoading?: boolean;
	hasError?: boolean;
	children?: React.ReactElement | React.ReactElement[];
};

const StatefulModal = (props: StatefulModalProps) => {
	return (
		<Modal
			isOpen={props.isOpen}
			closeOnEsc={!props.isLoading}
			closeOnOverlayClick={!props.isLoading}
			size={props.size}
			onClose={props.onClose}
		>
			<ModalOverlay/>
			<ModalContent {...props.modalContentProps}>
				<ModalHeader>
					<Heading size="3">
						{props.heading}
					</Heading>
				</ModalHeader>
				<ModalBody>
					<VStack spacing={4}>
						{
							props.hasError && (
								<Alert status="error">
									<AlertIcon/>
									<AlertDescription>Something went wrong, please try again later.</AlertDescription>
								</Alert>
							)
						}

						{props.children}
					</VStack>
				</ModalBody>

				<ModalFooter>
					<HStack spacing={4} alignItems="baseline" justifyContent="space-between" width="100%">
						<HStack spacing={2} d={{base: 'none', md: 'block'}}>
							<Button
								colorScheme="blue"
								mr={3}
								variant="secondary"
								isLoading={props.isLoading}
								onClick={props.onSecondaryAction ?? props.onClose}
							>
								{props.secondaryAction ?? 'Cancel'}
							</Button>

							{props.tertiaryAction && (
								<Button
									colorScheme="blue"
									mr={3}
									variant="link"
									isLoading={props.isLoading}
									onClick={props.onTertiaryAction ?? props.onClose}
								>
									{props.tertiaryAction}
								</Button>
							)}
						</HStack>

						<VStack spacing={2} d={{base: 'block', md: 'none'}}>
							<Button
								colorScheme="blue"
								mr={3}
								variant="secondary"
								isLoading={props.isLoading}
								onClick={props.onSecondaryAction ?? props.onClose}
							>
								{props.secondaryAction ?? 'Cancel'}
							</Button>

							{props.tertiaryAction && (
								<Button
									colorScheme="blue"
									mr={3}
									variant="link"
									isLoading={props.isLoading}
									onClick={props.onTertiaryAction ?? props.onClose}
								>
									{props.tertiaryAction}
								</Button>
							)}
						</VStack>

						<Button
							variant="primary"
							type="submit"
							disabled={props.isPrimaryActionDisabled}
							isLoading={props.isLoading}
							onClick={props.onPrimaryAction}
						>
							{props.primaryAction ?? 'Confirm'}
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default StatefulModal;

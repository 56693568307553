import {ThemeTypings} from '@chakra-ui/styled-system';
import simpleStrHash from './simple-str-hash';

const POSSIBLE_COLORS: Array<ThemeTypings['colorSchemes']> = ['red', 'purple', 'teal', 'blue', 'yellow'];

const getPseudoRandomAccentColorSchemeFromString = (string: string, schemes = POSSIBLE_COLORS): ThemeTypings['colorSchemes'] => {
	const lowercaseValue = string.toLowerCase();

	if (lowercaseValue.includes('advanced')) {
		return 'yellow';
	}

	if (lowercaseValue.includes('full')) {
		return 'red';
	}

	if (lowercaseValue.includes('upper')) {
		return 'purple';
	}

	if (lowercaseValue.includes('lower')) {
		return 'teal';
	}

	if (lowercaseValue.includes('core')) {
		return 'blue';
	}

	return schemes[simpleStrHash(string, schemes.length)];
};

export default getPseudoRandomAccentColorSchemeFromString;

import React, { FormEvent, useState } from 'react';
import { Input } from '@chakra-ui/react';
import useAPI from 'src/lib/hooks/use-api';
import { FavoritesBoardResponse, WorkoutResponse } from 'src/lib/types';
import useCustomSWR from 'src/lib/hooks/use-custom-swr';
import StatefulModal from './stateful-modal';

type CreateFavoritesBoardModalProps = {
	isOpen: boolean;
	workoutId?: WorkoutResponse['id'];
	onClose: () => void;
	onCreation?: (newBoard: FavoritesBoardResponse) => (void | Promise<void>);
};

const CreateFavoritesBoardModal = ({ isOpen, workoutId, onClose, onCreation }: CreateFavoritesBoardModalProps) => {
	const [isMutating, setIsMutating] = useState(false);
	const [hasMutationError, setHasMutationError] = useState(false);
	const [newBoardName, setNewBoardName] = useState('');
	const api = useAPI();

	const { revalidate } = useCustomSWR('users/favorites');

	const handleCreateNewBoard = async (event: FormEvent) => {
		event.preventDefault();

		setIsMutating(true);

		try {
			const ids = [];

			if (workoutId) {
				ids.push(workoutId);
			}

			const newBoard = await api.createFavoriteBoard({
				title: newBoardName,
				ids
			});

			await revalidate();

			if (onCreation) {
				await onCreation(newBoard);
			}

			setNewBoardName('');
			setHasMutationError(false);
			onClose();
		} catch {
			setHasMutationError(true);
		}

		setIsMutating(false);
	};

	return (
		<StatefulModal
			isOpen={isOpen}
			isLoading={isMutating}
			hasError={hasMutationError}
			heading="Create Board"
			modalContentProps={{ as: 'form', onSubmit: handleCreateNewBoard }}
			primaryAction="Create"
			isPrimaryActionDisabled={newBoardName.trim() === ''}
			onClose={onClose}
		>
			<Input
				placeholder="Board name"
				value={newBoardName}
				disabled={isMutating}
				onChange={event => {
					setNewBoardName(event.target.value);
				}} />
		</StatefulModal>
	);
};

export default CreateFavoritesBoardModal;

import React from 'react';
import { Button, ButtonProps, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import useCalendarEvent, { CalendarProvider, useCalendarEventProps } from 'src/lib/hooks/use-calendar-event';
import { CalendarIcon } from 'src/theme/icons';
import { ChevronDownIcon } from '@chakra-ui/icons';

type LoadingProps = {
	isLoading: true;
};

type ResolvedProps = {
	isLoading?: false;
	event: useCalendarEventProps;
};

type CommonProps = {
	isLoading?: boolean;
	variant: 'icon' | 'text';
	buttonProps?: ButtonProps;
};

export type AddToCalendarButtonProps = (LoadingProps | ResolvedProps) & CommonProps;

const AddToCalendarButton = (props: AddToCalendarButtonProps) => {
	const { promptAddFor } = useCalendarEvent(props.isLoading ? {
		title: '',
		description: '',
		uri: '',
		location: 'Online',
		start: new Date(),
		end: new Date()
	} : props.event);

	return (
		<Menu>
			{({ isOpen }) => (
				<>
					<MenuButton
						as={props.variant === 'text' ? Button : IconButton}
						aria-label="Add to calendar"
						variant="link"
						color="black"
						icon={props.variant === 'icon' ? <CalendarIcon boxSize={6} /> : undefined}
						leftIcon={props.variant === 'text' ? <CalendarIcon color="blue.500" boxSize={6} /> : undefined}
						rightIcon={props.variant === 'text' ? <ChevronDownIcon /> : undefined}
						rounded={props.variant === 'icon' ? 'md' : undefined}
						disabled={props.isLoading}
						size={props.variant === 'icon' ? 'xs' : undefined}
						{...props.buttonProps}
					>
						{props.variant === 'text' ? 'Add to Calendar' : ''}
					</MenuButton>
					<Portal>
						<MenuList py={0} display={isOpen ? 'block' : 'none'}>
							{
								Object.values(CalendarProvider).map(provider => (
									<MenuItem
										key={provider}
										_focus={{ bgColor: 'blue.200' }}
										onClick={() => {
											promptAddFor(provider);
										}}
									>
										{provider}
									</MenuItem>
								))
							}
						</MenuList>
					</Portal>
				</>
			)}
		</Menu>
	);
};

export default React.memo(AddToCalendarButton);
